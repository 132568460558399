import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import HomeMap from './routes/HomeMap';
import RegisterMarker from './routes/RegisterMarker';
import NewMarker from "./routes/NewMarker";

function App() {
    return (
        <Router>
            <div className="App">


                {/* Define Routes */}
                <Routes>
                    {/* Redirect the root path to /home-map */}
                    <Route path="/" element={<Navigate to="/home-map" replace />} />
                    <Route path="/home-map" element={<HomeMap />} />
                    <Route path="/register" element={<RegisterMarker />} />
                    <Route path="/new-marker" element={<NewMarker />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
