import React, { useState } from 'react';

function RegisterMarker() {
    const [longReg, setLongReg] = useState('');
    const [latReg, setLatReg] = useState('');
    const [comReg, setComReg] = useState('');
    const [nameReg, setNameReg] = useState('');
    const [typeReg, setTypeReg] = useState('');
    const [base64Reg, setBase64Reg] = useState('');
    const [secCode, setSecCode] = useState(''); // New state for secCode

    const handleLocationClick = async () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatReg(position.coords.latitude.toString());
                        setLongReg(position.coords.longitude.toString());
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                    },
                    (error) => {
                        alert('Unable to retrieve location. Please allow location access.');
                        reject(error);
                    }
                );
            } else {
                alert('Geolocation is not supported by your browser.');
                reject(new Error('Geolocation not supported'));
            }
        });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = `data:image/jpeg;base64,${reader.result.split(',')[1]}`;
                setBase64Reg(base64String);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const location = await handleLocationClick(); // Get location before sending

            const newMarker = {
                long_reg: location.longitude.toString() || longReg,
                lat_reg: location.latitude.toString() || latReg,
                com_reg: comReg,
                name_reg: nameReg,
                type_reg: typeReg,
                base64_reg: base64Reg,
                secCode: secCode, // Include secCode in the JSON
            };

            // Log the JSON data to the console before sending it
            console.log("Sending the following data to the server:", JSON.stringify(newMarker));

            const response = await fetch('https://lovecompany.xyz/sudodaemon/generalRegister/createRegisterMaster', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newMarker),
            });

            if (response.ok) {
                alert('Marker created successfully');
            } else {
                const responseBody = await response.json();
                alert(`Error: ${responseBody.message}`);
            }
        } catch (error) {
            alert('Error:', error);
        }
    };

    return (
        <div className="p-8">
            <h2 className="text-2xl mb-4">Register a New Marker SudoDaemon</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    placeholder="Comment"
                    value={comReg}
                    onChange={(e) => setComReg(e.target.value)}
                    className="w-full p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Marker Name"
                    value={nameReg}
                    onChange={(e) => setNameReg(e.target.value)}
                    className="w-full p-2 border rounded"
                />
                <select
                    value={typeReg}
                    onChange={(e) => setTypeReg(e.target.value)}
                    className="w-full p-2 border rounded"
                >
                    <option value="" disabled>
                        Select Marker Type
                    </option>
                    <option value="My marker">My marker</option>
                    <option value="Cops">Cops</option>
                    <option value="Generic Point">Generic Point</option>
                    <option value="Health">Health</option>
                    <option value="Danger">Danger</option>
                </select>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="w-full p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Security Code"
                    value={secCode}
                    onChange={(e) => setSecCode(e.target.value)} // Handle secCode input
                    className="w-full p-2 border rounded"
                />
                <button type="submit" className="w-full p-3 bg-blue-500 text-white rounded">
                    Register Marker
                </button>
            </form>
        </div>
    );
}

export default RegisterMarker;
