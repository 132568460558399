import React, { useState } from "react";
import imageCompression from 'browser-image-compression';

function NewMarker() {
    const [longReg, setLongReg] = useState('');
    const [latReg, setLatReg] = useState('');
    const [comReg, setComReg] = useState('');
    const [nameReg, setNameReg] = useState('');
    const [typeReg, setTypeReg] = useState('');
    const [base64Reg, setBase64Reg] = useState('');
    const [loading, setLoading] = useState(false);
    const [submittedData, setSubmittedData] = useState(null); // State to store the submitted data

    const handleLocationClick = async () => {
        return new Promise((resolve, reject) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        setLatReg(position.coords.latitude.toString());
                        setLongReg(position.coords.longitude.toString());
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                    },
                    (error) => {
                        alert('Unable to retrieve location. Please allow location access.');
                        reject(error);
                    }
                );
            } else {
                alert('Geolocation is not supported by your browser.');
                reject(new Error('Geolocation not supported'));
            }
        });
    };

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                // Compress the image
                const options = { maxSizeMB: 1, maxWidthOrHeight: 800, useWebWorker: true };
                const compressedFile = await imageCompression(file, options);

                // Convert image to JPEG and resize it to 512x512
                const image = new Image();
                const reader = new FileReader();

                reader.onloadend = () => {
                    image.src = reader.result;

                    image.onload = () => {
                        const canvas = document.createElement("canvas");
                        const ctx = canvas.getContext("2d");

                        // Resize the image to 512x512
                        canvas.width = 512;
                        canvas.height = 512;
                        ctx.drawImage(image, 0, 0, 512, 512);

                        // Convert to JPEG format
                        const jpegBase64 = canvas.toDataURL("image/jpeg");

                        // Set the base64 string with the 'data:image/jpeg;base64,' prefix
                        setBase64Reg(jpegBase64); // Include the full data URL
                    };
                };

                reader.readAsDataURL(compressedFile);
            } catch (error) {
                console.error("Error compressing image:", error);
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Show the loading screen

        // Check if all fields are populated
        if (!comReg || !nameReg || !typeReg || !base64Reg) {
            alert('Please fill in all fields before submitting.');
            setLoading(false);
            return;
        }

        try {
            const location = await handleLocationClick(); // Get location before sending

            const newMarker = {
                long_reg: location.longitude.toString() || longReg,
                lat_reg: location.latitude.toString() || latReg,
                com_reg: comReg,
                name_reg: nameReg,
                type_reg: typeReg,
                base64_reg: base64Reg,
            };

            // Log each field to check if they are correctly populated
            console.log('long_reg:', newMarker.long_reg);
            console.log('lat_reg:', newMarker.lat_reg);
            console.log('com_reg:', newMarker.com_reg);
            console.log('name_reg:', newMarker.name_reg);
            console.log('type_reg:', newMarker.type_reg);
            console.log('base64_reg:', newMarker.base64_reg);

            console.log("Sending the following data to the server:", JSON.stringify(newMarker));

            // Set the submitted data to be displayed
            setSubmittedData(newMarker);

            const response = await fetch('https://lovecompany.xyz/sudodaemon/generalRegister/createRegister', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newMarker),
            });

            if (response.ok) {
                alert('Marker created successfully');
                // window.location.href = 'https://lovecompany.xyz/'; // Redirect after success
            } else {
                const errorMessage = await response.text(); // Get the response body as text
                alert('Error creating marker: ' + errorMessage + newMarker.base64_reg);
            }
        } catch (error) {
            alert('Error: ' + error.message); // Log the actual error
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-8">
            {loading && (
                <div
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.8)',
                        color: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000,
                    }}
                >
                    <p>Loading...</p>
                </div>
            )}
            <h2 className="text-2xl mb-4">Register a New Marker 2</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    placeholder="Comment"
                    value={comReg}
                    onChange={(e) => setComReg(e.target.value)}
                    className="w-full p-2 border rounded"
                />
                <input
                    type="text"
                    placeholder="Marker Name"
                    value={nameReg}
                    onChange={(e) => setNameReg(e.target.value)}
                    className="w-full p-2 border rounded"
                />
                <select
                    value={typeReg}
                    onChange={(e) => setTypeReg(e.target.value)}
                    className="w-full p-2 border rounded"
                >
                    <option value="" disabled>
                        Select Marker Type
                    </option>
                    <option value="Generic Point">Generic Point</option>
                    <option value="Cops">Cops</option>
                    <option value="Health">Health</option>
                    <option value="Danger">Danger</option>
                </select>

                <div className="space-y-4">
                    {/* Button for opening the camera */}
                    <button
                        type="button"
                        className="w-full p-3 bg-gray-700 text-white rounded"
                        onClick={() => document.getElementById('cameraInput').click()}
                    >
                        Take Picture
                    </button>

                    {/* Button for selecting an image */}
                    <button
                        type="button"
                        className="w-full p-3 bg-gray-600 text-white rounded"
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        Select Image
                    </button>

                    {/* Hidden input for camera (rear camera) */}
                    <input
                        id="cameraInput"
                        type="file"
                        accept="image/*"
                        capture="environment"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />

                    {/* Hidden input for selecting an image */}
                    <input
                        id="fileInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>

                <button type="submit" className="w-full p-3 bg-purple-800 text-white rounded">
                    Register Marker
                </button>
            </form>

            {submittedData && (
                <div className="mt-4 p-4 border rounded bg-gray-100">
                    <h3 className="text-xl">Submitted Data</h3>
                    <pre>{JSON.stringify(submittedData, null, 2)}</pre>
                </div>
            )}

            {/* Show the base64 string */}
            {base64Reg && (
                <div className="mt-4">
                    <label className="block text-lg font-semibold">Base64 Image:</label>
                    <textarea
                        rows="4"
                        className="w-full p-2 border rounded"
                        value={base64Reg}
                        readOnly
                    />
                </div>
            )}

            {/* Show the image preview */}
            {base64Reg && (
                <div className="mt-4">
                    <label className="block text-lg font-semibold">Image Preview:</label>
                    <img src={`${base64Reg}`} alt="Uploaded Preview" className="w-64 h-64 object-cover mt-2" />
                </div>
            )}
        </div>
    );
}

export default NewMarker;
