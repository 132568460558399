import React, { useState, useEffect } from "react";
import {
    MapContainer,
    TileLayer,
    Marker,
    Popup,
    useMap,
    useMapEvents,
} from "react-leaflet";
import L from "leaflet";
import customMarkerImage from "../imgs/craneo.png";
import plyrMarkerImage from "../imgs/plyr2.png";
import copMarkerImage from "../imgs/favorito.png";
import genericMarkerImage from "../imgs/posicion.png";
import healtMarkerImage from "../imgs/hospital.png";
import dangerMarkerImage from "../imgs/bomba.png";
import { useNavigate } from "react-router-dom";

//

// Define custom icons for different types
const icons = {
    "My marker": L.icon({
        iconUrl: customMarkerImage,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38],
    }),
    Cops: L.icon({
        iconUrl: copMarkerImage,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38],
    }),
    "Generic Point": L.icon({
        iconUrl: genericMarkerImage,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38],
    }),
    Health: L.icon({
        iconUrl: healtMarkerImage,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38],
    }),
    Danger: L.icon({
        iconUrl: dangerMarkerImage,
        iconSize: [38, 38],
        iconAnchor: [19, 38],
        popupAnchor: [0, -38],
    }),
};

// Modify the `ZoomMarker` component to accept a custom icon
function ZoomMarker({ position, data, icon }) {
    const map = useMap();

    const handleClick = () => {
        map.setView(position, 18, { animate: true });
    };

    const renderBase64Image = (base64) => {
        if (!base64 || typeof base64 !== "string") {
            return <p style={{ color: "red" }}>No image available</p>;
        }
        return (
            <img
                src={base64}
                alt="Base64 Preview"
                style={{ maxWidth: "100px", maxHeight: "100px" }}
            />
        );
    };

    return (
        <Marker
            position={position}
            icon={icon}
            eventHandlers={{ click: handleClick }}
        >
            <Popup>
                <table style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                    <tr>
                        <th style={tableHeaderStyle}>Key</th>
                        <th style={tableHeaderStyle}>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(data).map(([key, value], index) => (
                        <tr key={index}>
                            <td style={tableDataStyle}>{key}</td>
                            <td style={tableDataStyle}>
                                {key === "Image" ? renderBase64Image(value) : value}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </Popup>
        </Marker>
    );
}

// Floating menu component to interact with the map
function FloatingMenu({ markers }) {
    const map = useMap();
    const defaultCenter = [20, 0];
    const defaultZoom = 2;
    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleMenu = () => {
        setIsCollapsed(!isCollapsed);
    };

    const zoomToLocation = (position) => {
        map.setView(position, 12, { animate: true });
    };

    const resetZoom = () => {
        map.setView(defaultCenter, defaultZoom, { animate: true });
    };

    return (
        <div>
            <button
                style={{
                    ...toggleButtonStyle,
                    position: "absolute",
                    top: "20px",
                    right: "10px",
                    zIndex: 1001,
                }}
                onClick={toggleMenu}
            >
                {isCollapsed ? "Show Menu" : "Hide Menu"}
            </button>
            {!isCollapsed && (
                <div style={menuStyle} className="floating-menu">
                    <h3>Menu</h3>
                    {markers.length > 0 ? (
                        markers.map((marker) => (
                            <button
                                key={marker.id}
                                style={buttonStyle}
                                onClick={() => zoomToLocation(marker.position)}
                            >
                                Go to {marker.description || `Marker ${marker.id}`}
                            </button>
                        ))
                    ) : (
                        <p>No markers available.</p>
                    )}
                    <button style={buttonStyle} onClick={resetZoom}>
                        Reset Zoom
                    </button>
                </div>
            )}
        </div>
    );
}

const menuStyle = {
    position: "absolute",
    top: "60px",
    right: "10px",
    background: "rgba(26,26,26,0.44)",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    maxHeight: "300px", // Ensure touch-friendly size
    overflowY: "auto", // Enable scrolling
    scrollbarWidth: "thin", // Firefox
    scrollbarColor: "#888 #444", // Firefox
    WebkitOverflowScrolling: "touch", // Enables smooth scrolling on touch devices
};

// Optional CSS for WebKit browsers
const menuContainerStyle = {
    ...menuStyle,
    '::-webkit-scrollbar': {
        width: "8px",
        height: "8px", // Add height for horizontal scrolling, if needed
    },
    '::-webkit-scrollbar-thumb': {
        backgroundColor: "#888",
        borderRadius: "4px",
    },
    '::-webkit-scrollbar-thumb:hover': {
        backgroundColor: "#555",
    },
};



const toggleButtonStyle = {
    padding: "8px 12px",
    borderRadius: "4px",
    backgroundColor: "#333",
    color: "white",
    border: "none",
    cursor: "pointer",
    zIndex: 1000,
};

// Custom icon definition for the marker
const customIcon = L.icon({
    iconUrl: customMarkerImage,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
});

// Component to handle user location
function ResetZoomButton({ userLocation }) {
    const map = useMap();
    const [mode, setMode] = useState("Locate me");  // Initial mode is "Locate me"

    const handleResetZoom = () => {
        if (userLocation) {
            // "Locate me" mode: Focus on the user with zoom level 18
            if (mode === "Locate me") {
                map.setView(userLocation, 18, { animate: true });
            }
            // "Free view" mode: Update map location without focusing on the user
            else {
                map.setView(userLocation, map.getZoom(), { animate: true });
            }
        }
    };

    const toggleMode = () => {
        // Toggle between "Locate me" and "Free view"
        setMode((prevMode) => (prevMode === "Locate me" ? "Free view" : "Locate me"));
    };

    return (
        <div>
{/*            <button onClick={toggleMode} style={resetButtonStyleSwitch}>
                Switch to {mode === "Locate me" ? "Free view" : "Locate me"}
            </button>*/}
        </div>
    );
}


function UserLocationMarker({ setUserPosition }) {
    const [position, setPosition] = useState(null);
    const [circle, setCircle] = useState(null);
    const map = useMap();

    useMapEvents({
        locationfound(e) {
            setPosition(e.latlng);
            setUserPosition(e.latlng); // Update userPosition in HomeMap
            map.setView(e.latlng, 18, { animate: true });
        },
        locationerror(e) {
            console.error("Geolocation error:", e.message);
        },
    });

    useEffect(() => {
        const geoWatch = navigator.geolocation.watchPosition(
            (position) => {
                const latLng = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setPosition(latLng);
                setUserPosition(latLng);
                map.setView(latLng, 18, { animate: true });
            },
            (error) => {
                console.error("Error retrieving user location:", error.message);
            },
            { enableHighAccuracy: true, maximumAge: 0, timeout: 5000 } // Optional options
        );

        return () => {
            navigator.geolocation.clearWatch(geoWatch); // Clean up the watch when component unmounts
        };
    }, [map, setUserPosition]);

    useEffect(() => {
        if (position) {
            if (circle) {
                circle.remove();
            }

            const newCircle = L.circle(position, {
                color: "purple",
                fillColor: "purple",
                fillOpacity: 0.3,
                radius: 10,
            }).addTo(map);

            setCircle(newCircle);
        }

        return () => {
            if (circle) {
                circle.remove();
            }
        };
    }, [position, map]);

    if (!position) {
        return null;
    }

    return (
        <Marker
            position={position}
            icon={L.icon({ iconUrl: plyrMarkerImage, iconSize: [38, 38] })}
        >
            <Popup>You are here!</Popup>
        </Marker>
    );
}

// Floating reset button style
const resetButtonStyle = {
    position: "absolute",
    bottom: "100px",
    left: "10px",
    backgroundColor: "rgba(61,6,161,0.72)",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
};

// Floating reset button style switch
const resetButtonStyleSwitch = {
    position: "absolute",
    bottom: "50px",
    left: "10px",
    backgroundColor: "rgba(61,6,161,0.72)",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
};

// Function to check if the date is older than 2 hours
const isOlderThanTwoHours = (dateString) => {
    const currentDate = new Date();
    const markerDate = new Date(dateString);
    const timeDiff = currentDate - markerDate;
    const twoHoursInMillis = 2 * 60 * 60 * 1000;
    return timeDiff > twoHoursInMillis;
};

function HomeMap() {
    const [markers, setMarkers] = useState([]);
    const [galleryData, setGalleryData] = useState([]);
    const [userPosition, setUserPosition] = useState(null);
    const navigate = useNavigate();

    // Fetch markers from API
    const fetchMarkers = async () => {
        try {
            const response = await fetch("https://lovecompany.xyz/sudodaemon/generalRegister/getRegister");
            const data = await response.json();

            const galleryData = data?.galleryData || [];
            if (!Array.isArray(galleryData)) {
                console.error("Invalid gallery data format received from API.");
                return;
            }

            const newMarkers = galleryData
                .map((item) => {
                    const lat = parseFloat(item.lat_reg);
                    const lon = parseFloat(item.long_reg);
                    const type = item.type_reg;

                    if (isNaN(lat) || isNaN(lon)) {
                        console.warn(`Invalid coordinates for item with id ${item.id}`);
                        return null;
                    }

                    if (type === "Cops" && isOlderThanTwoHours(item.date_reg)) {
                        return null;
                    }

                    return {
                        id: item.id,
                        position: [lat, lon],
                        icon: icons[type] || icons["Generic Point"],
                        description: item.name_reg || `Marker ${item.id}`,
                        data: {
                            "ID Register": item.id_reg,
                            Name: item.name_reg || "Unknown",
                            Comment: item.com_reg || "No comment available",
                            Image: item.base64_reg || "404",
                            Date: item.date_reg || "404",
                            Type: item.type_reg || "404",
                        },
                    };
                })
                .filter((marker) => marker !== null);

            setMarkers(newMarkers);
            setGalleryData(galleryData);
        } catch (error) {
            console.error("Error fetching markers:", error);
        }
    };

    useEffect(() => {
        fetchMarkers();
        const interval = setInterval(fetchMarkers, 10000); // Fetch markers every 10 seconds
        return () => clearInterval(interval); // Cleanup on unmount
    }, []);

    // Update user position
    useEffect(() => {
        const updateUserPosition = () => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latLng = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setUserPosition(latLng);
                },
                (error) => {
                    console.error("Error retrieving user location:", error.message);
                }
            );
        };

        const interval = setInterval(updateUserPosition, 10000); // Update every 10 seconds
        return () => clearInterval(interval);
    }, []);

    // Function to check if the date is older than 2 hours
    const isOlderThanTwoHours = (dateString) => {
        const currentDate = new Date();
        const markerDate = new Date(dateString);
        const timeDiff = currentDate - markerDate;
        const twoHoursInMillis = 2 * 60 * 60 * 1000;
        return timeDiff > twoHoursInMillis;
    };

    // Log gallery data and user position for debugging
    useEffect(() => {
        if (userPosition) {
            console.log("User Position:", userPosition);
        }
        if (galleryData.length) {
            console.log("Gallery Data:", galleryData);
        }
    }, [userPosition, galleryData]);

    return (
        <MapContainer center={userPosition || [20, 0]} zoom={12} style={{ height: "100vh" }}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <FloatingMenu markers={markers} />
            <ResetZoomButton userLocation={userPosition} />
            <UserLocationMarker setUserPosition={setUserPosition} />
            {markers.map((marker) => (
                <ZoomMarker
                    key={marker.id}
                    position={marker.position}
                    data={marker.data}
                    icon={marker.icon}
                />
            ))}
            <button
                style={{
                    position: "absolute",
                    bottom: "50px",
                    right: "20px",
                    padding: "10px 20px",
                    background: "rgba(54,4,140,0.79)",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    zIndex: 1000,
                }}
                onClick={() => navigate("/new-marker")}
            >
                New Marker
            </button>
        </MapContainer>
    );
}


const newMarkerButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#007BFF",
    color: "white",
    padding: "10px 20px",
    border: "none",
    borderRadius: "8px",
    cursor: "pointer",
    fontSize: "16px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
};

const buttonStyle = {
    display: "block",
    margin: "10px 0",
    padding: "8px 12px",
    backgroundColor: "rgba(54,4,140,0.79)",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    zIndex: 1000,
};

const tableHeaderStyle = {
    backgroundColor: "#f2f2f2",
    padding: "8px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
};

const tableDataStyle = {
    padding: "8px",
    textAlign: "left",
    borderBottom: "1px solid #ddd",
};

//add marker floating button
// Styles for the floating button and menu
const floatingButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#3d06a1",
    color: "white",
    padding: "10px",
    borderRadius: "50%",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    cursor: "pointer",
    zIndex: 1001,
    fontSize: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

};

const floatingMenuStyle = {
    position: "absolute",
    top: "60px",
    right: "10px",
    backgroundColor: "white",
    padding: "10px",
    borderRadius: "8px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
};

// Add a floating button and menu
function FloatingNewMarkerMenu({ addMarker }) {
    const [menuVisible, setMenuVisible] = useState(false);
    const [newMarkerPosition, setNewMarkerPosition] = useState({
        lat: "",
        lng: "",
    });

    const toggleMenu = () => {
        setMenuVisible((prev) => !prev);
    };

    const handleAddMarker = () => {
        const { lat, lng } = newMarkerPosition;
        if (lat && lng) {
            addMarker({
                position: [parseFloat(lat), parseFloat(lng)],
                description: "New Marker",
            });
            setMenuVisible(false);
            setNewMarkerPosition({ lat: "", lng: "" });
        } else {
            alert("Please enter valid latitude and longitude values.");
        }
    };

    return (
        <>
            <div style={floatingButtonStyle} onClick={toggleMenu}>
                +
            </div>
            {menuVisible && (
                <div style={floatingMenuStyle}>
                    <h4>New Marker</h4>
                    <input
                        type="text"
                        placeholder="Latitude"
                        value={newMarkerPosition.lat}
                        onChange={(e) =>
                            setNewMarkerPosition((prev) => ({ ...prev, lat: e.target.value }))
                        }
                        style={{ marginBottom: "8px", width: "100%", padding: "6px" }}
                    />
                    <input
                        type="text"
                        placeholder="Longitude"
                        value={newMarkerPosition.lng}
                        onChange={(e) =>
                            setNewMarkerPosition((prev) => ({ ...prev, lng: e.target.value }))
                        }
                        style={{ marginBottom: "8px", width: "100%", padding: "6px" }}
                    />
                    <button
                        onClick={handleAddMarker}
                        style={{ padding: "8px 12px", width: "100%" }}
                    >
                        Add Marker
                    </button>
                </div>
            )}
        </>
    );
}

export default HomeMap;
